import React from "react"
import { useTranslation } from "react-i18next"
import JoinSlideFour from "../components/joinSlides/joinSlideFour"
import JoinSlideOne from "../components/joinSlides/joinSlideOne"
import JoinSlideThree from "../components/joinSlides/joinSlideThree"
import JoinSlideTwo from "../components/joinSlides/joinSlideTwo"
import Layout from "../components/layout"
import Seo from "../components/seo"

const JoinPage = ({ pageContext: { originalPath } }) => {
  const { t } = useTranslation()
  return (
    <Layout title={t("join")} originalPath={originalPath}>
      <Seo title={t("join")} />
      <JoinSlideOne />
      <JoinSlideTwo />
      <JoinSlideThree />
      <JoinSlideFour />
    </Layout>
  )
}

export default JoinPage