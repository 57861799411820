import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material"
import { Button } from "gatsby-material-ui-components"
import React from "react"
import SectionWrapper from "../sectionWrapper"
import { blueGrey } from "@mui/material/colors"


const commitmentTypeData = [
  {
    title: "potential for impact",
    detail:
      "a strong commitment to achieve significant, transformative change in service of one of the four action areas with financial investment, policy and advocacy-based interventions and/or programmatic endeavors",
  },
  {
    title: "high-level endorsement",
    detail:
      "a strong commitment that is endorsed at the highest level and represented at all relevant platforms in relation to Generation Equality",
  },
  {
    title: "multi-stakeholder",
    detail: "a strong commitment is collective across multiple stakeholders",
  },
  {
    title: "Financial resources",
    detail:
      "a strong commitment is resourced by new and scaled funds (clearly expressed and integrated into budgets)",
  },
]

const JoinSlideThree = () => {
  // const classes = useStyles()
  return (
    <SectionWrapper light>
      <Typography variant="h2">What is a strong commitment?</Typography>
      <Grid container spacing={1}>
        {commitmentTypeData.map(({ title, detail }) => (
          <Grid key={title} item xs={12} md={3}>
            <Card style={{ backgroundColor: blueGrey[50], height: "100%" }}>
              <CardHeader
                title={title}
                style={{ height: 88, alignItems: "flex-start" }}
              />
              <CardContent>{detail}</CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" flexWrap="wrap" pt={4}>
        <Button
          variant="contained"
          disableElevation
          href="https://mcusercontent.com/c7919f7997d82f8ddc1224ee1/files/39204d09-848f-6b54-889c-865309647682/Commitment_Making_FAQ_17_June_V1_.pdf"
          style={{ margin: 4, minWidth: 200 }}
          target="_blank"
          rel="noreferrer"
        >
          FAQs
        </Button>
        <Button
          variant="contained"
          disableElevation
          href="https://commitments.generationequality.org"
          style={{ margin: 4, minWidth: 200 }}
          target="_blank"
          rel="noreferrer"
        >
          Commit here
        </Button>
      </Box>
    </SectionWrapper>
  )
}

export default JoinSlideThree
