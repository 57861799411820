import { Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const JoinSlideOne = () => {
  return (
    <SectionWrapper light>
      <Typography variant="h2" component="p" gutterBottom>
        Let's leverage technology and innovation for gender equality together. 
      </Typography>
      <Typography variant="h2" component="p" color="primary">
        Join the Action Coalition on Technology and Innovation for Gender
        Equality by making a commitment.
      </Typography>
    </SectionWrapper>
  )
}

export default JoinSlideOne