import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const commitmentTypeData = [
  {
    title: "Programmatic",
    detail:
      "To deliver programs, services, research or other actions in service of the four action areas, with a focus on scale",
  },
  {
    title: "Policy",
    detail:
      "To demonstrate how an action can be translated to concrete policy change in the context of a government, company or organization",
  },
  {
    title: "Advocacy",
    detail:
      "To amplify and mobilize support for transformative change to achieve the goals of the action coalition ",
  },
  {
    title: "Financial",
    detail:
      "To support the realization of an action to advance tech and innovation for gender equality results",
  },
]

const JoinSlideTwo = () => {
  return (
    <SectionWrapper>
      <Typography variant="h2">
        What type of commitments can be made?
      </Typography>
      <Grid container spacing={1}>
        {commitmentTypeData.map(({ title, detail }) => (
          <Grid key={title} item xs={12} md={3}>
            <Card style={{height: "100%"}}>
              <CardHeader title={title} />
              <CardContent>{detail}</CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </SectionWrapper>
  )
}

export default JoinSlideTwo