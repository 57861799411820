import { Box, Container, Typography } from "@mui/material"
import React from "react"
import SectionWrapper from "../sectionWrapper"

const JoinSlideFour = () => {
  return (
    <SectionWrapper>
      <Typography variant="h3" style={{ textTransform: "lowercase" }}>
        <strong>TOGETHER WE CAN MOVE THE NEEDLE ON GENDER EQUALITY!</strong> ​
      </Typography>
      {/* <Box textAlign="center">
        <Button
          disableElevation
          variant="contained"
          href="https://forum.generationequality.org/form/membership-application-form"
        >
          COMMIT HERE TODAY!
        </Button>
      </Box> */}
      <Container maxWidth="sm" disableGutters>
        <Box pt={5}>
          <div
            style={{
              width: "100%",
              height: 0,
              position: "relative",
              paddingBottom: "56.250%",
            }}
          >
            <iframe
              title="A video"
              src="https://streamable.com/e/tkmq8g?loop=0"
              frameborder="0"
              width="100%"
              height="100%"
              allowfullscreen
              style={{
                width: "100%",
                height: "100%",
                position: "absolute",
                left: 0,
                top: 0,
                overflow: "hidden",
              }}
            ></iframe>
          </div>
        </Box>
      </Container>
    </SectionWrapper>
  )
}

export default JoinSlideFour